/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

.center-align {
  text-align: center !important;
}

.flexbox {
  display: flex;
}

//currencyExchangeMarginComponent,vendorRatingComponent Common Css
.currencyExchangeTable,
.vendorRatingTable {
  .ant-table-thead > tr > th {
    background: #ff5757;
    color: #fff;
    text-align: center;
  }
}

.currencyExchangeTable,
.vendorRatingTable {
  .ant-table-tbody > tr > td {
    padding: 8px 8px;
  }
}

.currencyExchangeForm,
.vendorRatingForm {
  .ant-form-item {
    margin-bottom: 0;
  }

  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
    text-align: center;
  }

  .ant-input-number {
    width: 100%;
  }
}

//---------------END---------
//vendor rating component buttons
.btnPlusAdd {
  background: #ff5757;
  color: #fff;
  margin: 20px 0px;
  padding-bottom: 10px;
}

.btnSave {
  margin: 20px 0px;
}

//end
//manage-job component,bids-on-rfq ,threeWayMatchTable common css
.threeWayMatchTable,
.manageJobTable,
.bids-on-rfq-table,
.activityCard {
  .ant-table-tbody {
    tr {
      box-shadow: 0 3px 8px 0 rgba(201, 200, 200, 0.32);
      background: white;
      margin-bottom: 10px;
    }
  }
}

.threeWayMatchTable,
.bids-on-rfq-table,
.manageJobTable,
.activityCard {
  .ant-table table {
    border-collapse: separate;
    border-spacing: 0 1em;
    // margin-bottom: 30px;
  }
}

.threeWayMatchTable,
.manageJobTable,
.bids-on-rfq-table,
.activityCard {
  .ant-table-tbody {
    tr {
      td {
        border: none;
      }
    }
  }
}

//end
//BidDetailsComponent,RfqSummaryComponent common css
.leftBorder {
  position: relative;
  border-left: 1.5px solid #c9ced6;
  overflow: inherit;
  height: 100%;
  padding-left: 20px;
}

//end
.backArrow {
  margin-right: 10px;
  cursor: pointer;
  font-size: 32px;
}

.ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  min-height: 60vh;
  max-height: 100vh;
}

//vendor-Component,Owner-component
.generatePw {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
}

.anticon {
  vertical-align: 0;
}

.full-page-spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-close {
  outline: none !important;
}

.ant-drawer-close {
  outline: none !important;
}

.br-10 {
  border-radius: 10px;
}

.header-search-zone {
  .inputbox-search {
    padding: 6px 11px;
  }

  .ant-input-group-addon:last-child {
    border: 1px solid #d9d9d9 !important;
  }

  .ant-input-search-button {
    height: 33px !important;
  }
}

@media (max-width: 1200px) {
  .mobile-top10 {
    margin-top: 10px;
  }

  .vendor-header {
    .adjusment {
      text-align: left !important;
    }

    .searchBar {
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 1000px) {
  .search-results {
    width: 100% !important;
  }
}

.line1 {
  line-height: 1;
}

.wrap-data-ifrequired {
  // display: flex;
  // flex-flow: wrap;
  .ant-page-header-heading {
    flex-wrap: wrap;
  }
}

.scroll-cards {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-cards-body {
  .ant-card-body {
    height: calc(100vh - 150px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.bids-list {
  .scroll-cards-body {
    .ant-card-body {
      height: auto;
      overflow-y: initial;
      overflow-x: initial;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .bids-list {
    .scroll-cards-body {
      .ant-card-body {
        width: auto;
        overflow-x: auto;
      }
    }
  }
}

.vendorTable {
  .ant-card-body {
    min-height: calc(100vh - 150px);
  }
}

.common-card {
  .ant-card-body {
    min-height: calc(100vh - 150px);
  }
}

@media (max-width: 1250px) {
  .scroll1250 {
    width: 100%;
    overflow: scroll;
    .ant-table-container {
      width: 956px;
    }
    .scroll992 {
      width: 100%;
      overflow: scroll;
      .ant-table-container {
        width: 956px;
      }
    }
  }
}

@media (max-width: 992px) {
  .scroll992 {
    width: 100%;
    overflow: scroll;
    .ant-table-container {
      width: 956px;
    }
  }
}
